/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import CONFIG from 'config';
import qs from "qs";
import authService from 'redux/auth';
import { checkDurationAndReload, setAuthAttemptTime, isAuthAttemptSet } from 'utils/auth/auth';
import { isIE } from 'utils/mobile/mobile';

let CancelToken = axios.CancelToken;
let cancel;

const {
  DISPLAY_TEXT: {
    ERROR_MESSAGES: {
      INVALID_TOKEN,
      AUTH_FAILED,
      SERVER_ERROR,
      FORBIDDEN,
      NOT_FOUND,
      UNKNOWN_CODE,
      NETWORK_ERROR
    }
  }
} = CONFIG;

const invalidToken = () => {
  if (!isAuthAttemptSet()) setAuthAttemptTime();

  checkDurationAndReload(isIE() ? 25 : 15);

  return Promise.reject(new axios.Cancel(INVALID_TOKEN));
};

export const cancelAxiosRequest = () => {
  if (cancel !== undefined) {
    cancel();
  }
}

export const doAxiosRequest = async (request) => {
  const { method, endpoint, headers, params, responsetype, file } = request;
  let formdata = params;

  if (typeof headers !== "undefined" && headers['content-type'] === CONFIG.HTTP_CONTENT_TYPES.urlencoded) {
    formdata = qs.stringify(params); // need to stringify to convert it for "content type urlencoded"
  }

  if (method === 'put') {
    let options = {
      headers: headers
    };
    return await axios.put(endpoint, file, options);
  } else {
    return await axios({
      method: method,
      url: endpoint,
      headers: headers,
      data: formdata,
      responseType: responsetype,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    });
  }
}

// You can specify config defaults that will be applied to every request.
axios.defaults.baseURL = CONFIG.SERVER_URL;

/* You can intercept requests or responses before they are handled by then or catch.*/

// This is a request interceptor
axios.interceptors.request.use(async (config) => {
  const accessToken = await authService.getAccessToken();
  if (!accessToken) {
    return Promise.reject(
      new axios.Cancel(AUTH_FAILED)
    );
  }
  let authHeader = {};
  if (typeof config.headers['noAuthHeader'] === 'undefined') { // send no bearer token at all if noAuthHeader is set true    
    let conditionHeader = config.headers['customAuthHeader'] !== 'undefined' && !!config.headers['customAuthHeader'];
    authHeader = {
      [conditionHeader ? 'specifiedAuthorization' : 'authorization']: `Bearer ${accessToken}`
    };
    if (conditionHeader) {
      delete config.headers.authorization;
    }
  }

  // Do something before request is sent
  return {
    ...config,
    headers: {
      ...config.headers,
      ...authHeader,
      'x-api-key': config.headers['x-api-key'] || CONFIG.X_API_KEY
    }
  };
},
  // Do something with request error
  error => Promise.reject(error)
);

// This is a response interceptor
axios.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this ufunction to trigger
    // Do something with response data
    let isCustomHeader = response.config.headers['customAuthHeader'];
    return isCustomHeader ? response : response.data;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      console.error('AXIOS ERROR 1', error.response.status, error.message, error.config.url);
      switch (error.response.status) {
        case 500:
          return Promise.reject({ message: SERVER_ERROR, error });
        case 401:
          return invalidToken(); //change taken from smart search minerva-search-docviz
        case 403:
          return Promise.reject({ message: FORBIDDEN, error });
        case 404:
        case 423:
          return Promise.reject({ message: NOT_FOUND, error });
        default:
          return Promise.reject(new Error(UNKNOWN_CODE, error.response));
      }
    } else if (error.request) {
      console.error('AXIOS ERROR 2', error.request);
      return Promise.reject(new Error(NETWORK_ERROR, error.request));
    } else {
      console.error('AXIOS ERROR 3', error);
      return Promise.reject({ cancelled: true });
    }
  }
);
