// Constant
import { SN_DASHBOARD } from "redux/constants";

export const getSnDashboardPending = () => ({
  type: SN_DASHBOARD.SN_DASHBOARD_API_PENDING,
});

export const getSnDashboardFailure = (error) => ({
  type: SN_DASHBOARD.SN_DASHBOARD_API_FAILURE,
  payload: error
});

export const getSnDashboardSuccess = (data) => ({
  type: SN_DASHBOARD.SN_DASHBOARD_API_SUCCESS,
  payload: data
});

export const getSnDashboardData = (data) => ({
  type: SN_DASHBOARD.GET_SN_DASHBOARD_DATA,
});

export const setSnDashboardData = (data) => ({
  type: SN_DASHBOARD.SET_SN_DASHBOARD_DATA,
  payload: data,
});

export const snDashboardAPITakingTime = () => ({
  type: SN_DASHBOARD.SN_DASHBOARD_API_TAKING_LONG,
  payload: true,
});

export const setSnDashboardStatusList = (data) => ({
  type: SN_DASHBOARD.SET_SN_DASHBOARD_STATUS_LIST,
  payload: data,
});