// Constant
import { ALL_SERVICES } from "redux/constants";

export const getAllServicesPending = () => ({
    type: ALL_SERVICES.GET_ALL_SERVICES_PENDING,
});

export const getAllServicesFailure = (error) => ({
    type: ALL_SERVICES.GET_ALL_SERVICES_FAILURE,
    payload: {
        error: error
    }
});

export const getAllServicesSuccess = (data) => ({
    type: ALL_SERVICES.GET_ALL_SERVICES_SUCCESS,
    payload: {
        data
    }
});

export const getAllServices = () => ({
    type: ALL_SERVICES.GET_ALL_SERVICES,
});


export const getMFUPending = () => ({
    type: ALL_SERVICES.GET_MFU_PENDING,
});

export const getMFUFailure = (error) => ({
    type: ALL_SERVICES.GET_MFU_FAILURE,
    payload: {
        error: error
    }
});

export const getMFUSuccess = (data) => ({
    type: ALL_SERVICES.GET_MFU_SUCCESS,
    payload: {
        data
    }
});

export const getMFU = () => ({
    type: ALL_SERVICES.GET_MFU,
});

export const getBannersPending = () => ({
    type: ALL_SERVICES.GET_BANNERS_PENDING,
});

export const getBannersFailure = (error) => ({
    type: ALL_SERVICES.GET_BANNERS_FAILURE,
    payload: {
        error: error
    }
});

export const getBannersSuccess = (data) => ({
    type: ALL_SERVICES.GET_BANNERS_SUCCESS,
    payload: {
        data
    }
});

export const getBanners = () => ({
    type: ALL_SERVICES.GET_BANNERS,
});

export const getHelpfulResourcesPending = () => ({
    type: ALL_SERVICES.GET_HELPFUL_RESOURCES_PENDING,
});

export const getHelpfulResourcesFailure = (error) => ({
    type: ALL_SERVICES.GET_HELPFUL_RESOURCES_FAILURE,
    payload: {
        error: error
    }
});

export const getHelpfulResourcesSuccess = (data) => ({
    type: ALL_SERVICES.GET_HELPFUL_RESOURCES_SUCCESS,
    payload: {
        data
    }
});

export const getHelpfulResources = () => ({
    type: ALL_SERVICES.GET_HELPFUL_RESOURCES,
});

export const getActiveServicesCountPending = () => ({
    type: ALL_SERVICES.GET_ACTIVE_SERVICES_COUNT_PENDING,
});

export const getActiveServicesCountFailure = (error) => ({
    type: ALL_SERVICES.GET_ACTIVE_SERVICES_COUNT_FAILURE,
    payload: {
        error: error
    }
});

export const getActiveServicesCountSuccess = (data) => ({
    type: ALL_SERVICES.GET_ACTIVE_SERVICES_COUNT_SUCCESS,
    payload: {
        data
    }
});

export const getActiveServicesCount = () => ({
    type: ALL_SERVICES.GET_ACTIVE_SERVICES_COUNT,
});