import { TOOLNAME, PAGES, PRIMARY_CATEGORY } from "./analytics_constants";
import { getStaffEmailID } from "utils/auth/auth";
export default {
  async initialize({
    page,
    category = PRIMARY_CATEGORY.CTS,
    trackPageLoad,
    toolName = TOOLNAME.CASE_TEAM_SERVICES
  }) {
    if (trackPageLoad) {
      const staffEmailID = await getStaffEmailID();
      const data = {
        page: {
          pageInfo: {
            pageName: page ? PAGES[page] : PAGES.CTS_HOME,
          },
          headerInfo: {
            staffEmailID: staffEmailID, //eVar23
            toolName: toolName, //eVar80
          },
          category: {
            primaryCategory: category, //eVar50
          },
        },
      };
      window.digitalData = data;
      this.track("virtual_page_load");
    }
  },

  track(
    eventName,
    options = {
      integrations: {
        Adobe: true
      },
    }
  ) {
    try {
      if (options.integrations.Adobe) {
        this.waitForElement(window._satellite, eventName);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  },

  setGlobalProps(adobeAnalyticsData) {
    window.digitalData = adobeAnalyticsData;
  },

  waitForElement(satellite = window._satellite, eventName = "virtual_page_load") {
    if (typeof satellite !== "undefined") {
      satellite.track(eventName);
    }
    else {
      setTimeout(this.waitForElement, 500, satellite, eventName);
    }
  }
};