import React from "react";
import Image from "components/shared/Image";
import Spinner1 from "assets/images/spinner.gif";
import "./Spinner.scss";

export const Spinner = ({ isSnDashboardAPITakingTime }) => {
    return (
        <div className="spinnerWrapper">
            <div className="spinner">
                <Image src={Spinner1} alt="Spinner" />
                <p className="spinnerText1">{isSnDashboardAPITakingTime ? "It's taking longer than usual." : "Your requests are being loaded."}</p>
                {!isSnDashboardAPITakingTime && <p className="spinnerText2">Please wait...</p>}
            </div>
        </div>
    )
}
