import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { USER_SESSION } from "redux/constants";

import {
    getActiveUserSessionFailure,
    getActiveUserSessionSuccess,
    getActiveUserSessionPending
} from "./usersession.actions";

export function* getActiveUserSession() {
    try {
        const { X_API_KEY, API_URL: { GET_ACTIVE_SESSION_ID } } = CONFIG;
        yield put(getActiveUserSessionPending());
        const endpoint = GET_ACTIVE_SESSION_ID();
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        // uncomment the below code for blank session
        // res = { chat_session_id: "" }
        // console.log('res: ', res);
        yield put(getActiveUserSessionSuccess(res));
    } catch (error) {
        console.error("error: ", error)
        yield put(getActiveUserSessionFailure(error?.message));
    }
}


export default function* userSessionSaga() {
    try {
        yield takeLatest(USER_SESSION.GET_ACTIVE_USER_SESSION, getActiveUserSession);
    } catch (error) {
        yield put(moduleFailure(error, 'User Session Saga'));
    }
}