import React from "react";
import Image from "components/shared/Image";
import ErrorImage from "assets/images/error_image.png";
import "./ErrorComponent.scss";

export const ErrorComponent = () => {
    return (
        <div className="errorWrapper cts-error-page" >
            <h1 className="errorWrapper__heading">
                <Image src={ErrorImage} alt="ErrorImage" className="error-image" />
            </h1>
            <div className="errorWrapper__message">LOOKS LIKE WE ARE EXPERIENCING AN ISSUE!</div>
            <div className="errorWrapper__helpText">Please click REFRESH or RAISE an ISSUE</div>
            <br />

            <div className="margin-auto">
                <a href="/my-requests" className="btn primary button">Refresh</a>
                <a href="mailto:ITServiceCenter@bcg.com?subject=Error on My Requests tab CTS Platform."
                    className="btn primary secondary button">Raise
                    an
                    issue</a>
            </div>
        </div>
    )
}
