import React from "react";
import { saveMFU } from "redux/actions/allservices/allservices.saga";
import { sendAdobeAnaltics } from "utils/helpers/helpers";
import { PAGES } from "../../utils/analytics/analytics_constants";
import ReactHtmlParser from 'html-react-parser';

import "./HelpfulResource.scss";

export const HelpfulResource = ({ data }) => {

    const buttonClick = async (serviceId, buttonText, subCategory) => {
        await saveMFU(serviceId);
        await sendAdobeAnaltics(buttonText, subCategory, null, "OP_CATEGORY_BUTTON_EVENTS", "Helpful Resources", PAGES.CTS_HELPFUL_RESOURCES);
    }

    const buttonClickForEmail = async (linkUrl, serviceId, buttonText, subCategory) => {
        eval(linkUrl);
        buttonClick(serviceId, buttonText, subCategory);
    }


    return (
        <div class="helpful-resource">
            <div class="heading">
                <div class="field-heading">
                    {data.heading}
                </div>
            </div>
            <div class="sub-heading">
                <div class="field-description">
                    {ReactHtmlParser(data.description)}
                </div>
            </div>
            <div class="helpful-resources-links">
                <div className="left-button-container">
                    {data.links.filter(link => link.position === "Left")
                        .map(link => (
                            <>
                                {
                                    link.linkUrl.indexOf("javascript:window.open") === -1 ?
                                        <a class="learn-button" rel="noopener noreferrer" href={link.linkUrl} target="_blank" onClick={() => buttonClick(data.id, link.linkText, data.heading)}>
                                            {link.linkText}
                                        </a> :
                                        <a class="learn-button" rel="noopener noreferrer" onClick={() => buttonClickForEmail(link.linkUrl, data.id, link.linkText, data.heading)}>
                                            {link.linkText}
                                        </a >
                                }
                            </>
                        ))}
                </div>
                <div className="right-button-container">
                    {data.links.filter(link => link.position === "Right")
                        .map(link => (
                            <>
                                {
                                    link.linkUrl.indexOf("javascript:window.open") === -1 ?
                                        <a class="request-now" rel="noopener noreferrer" href={link.linkUrl} target="_blank" onClick={() => buttonClick(data.id, link.linkText, data.heading)}>
                                            {link.linkText}
                                        </a> :
                                        <a class="request-now" rel="noopener noreferrer" onClick={() => buttonClickForEmail(link.linkUrl, data.id, link.linkText, data.heading)}>
                                            {link.linkText}
                                        </a>
                                }
                            </>
                        ))}
                </div>
            </div>
        </div>
    );
};
