import { SN_DASHBOARD } from "redux/constants";
// default name
export const name = 'sndashboard';
// initial State 
export const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  snDashboardLoader: true,
  snDashboardAPITakingTime: false,
  snDashboardData: {},
  snDashboardStatusList: [],
  peopleApiData: []
};

// selectors
export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getSnDashboardData: (state) => state[name].snDashboardData,
  getSnDashboardStatusList: (state) => state[name].snDashboardStatusList,
  getSnDashboardDataLoader: (state) => state[name].snDashboardLoader,
  getSnDashboardAPITakingTime: (state) => state[name].snDashboardAPITakingTime,
  getPeopleApiData: (state) => state[name].peopleApiData,
};

/**
 * @export
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case SN_DASHBOARD.SN_DASHBOARD_API_PENDING:
      return {
        ...state,
        error: false,
        errorMessage: '',
        snDashboardLoader: true,
        snDashboardData: action.payload
      };
    case SN_DASHBOARD.SN_DASHBOARD_API_SUCCESS:
      return {
        ...state,
        error: false,
        errorMessage: '',
        snDashboardLoader: false,
        snDashboardData: action.payload,
        snDashboardAPITakingTime: false
      };
    case SN_DASHBOARD.SN_DASHBOARD_API_TAKING_LONG:
      return {
        ...state,
        snDashboardAPITakingTime: action.payload
      };
    case SN_DASHBOARD.SN_DASHBOARD_API_FAILURE:
      return {
        ...state,
        error: true,
        snDashboardLoader: false,
        errorMessage: action.payload
      };
    case SN_DASHBOARD.SET_SN_DASHBOARD_DATA:
      return {
        ...state,
        error: false,
        errorMessage: '',
        snDashboardData: action.payload
      };
    case SN_DASHBOARD.SET_SN_DASHBOARD_STATUS_LIST:
      return {
        ...state,
        snDashboardStatusList: action.payload
      };
    case SN_DASHBOARD.PEOPLE_API_DATA_PENDING:
    case SN_DASHBOARD.PEOPLE_API_DATA_RESET:
      return {
        ...state,
        error: false,
        errorMessage: "",
        peopleApiData: [],
      };
    case SN_DASHBOARD.PEOPLE_API_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        errorMessage: "",
        peopleApiData: action.payload,
      };
    default:
      return state;
  }
}

