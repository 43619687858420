import { put, call, takeEvery } from 'redux-saga/effects';
import CONFIG from "config";
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { ALL_SERVICES } from "redux/constants";
import jwt_decode from "jwt-decode";
import authService from 'redux/auth';

import {
    getAllServicesFailure,
    getAllServicesSuccess,
    getAllServicesPending,
    getMFUPending,
    getMFUFailure,
    getMFUSuccess,
    getBannersPending,
    getBannersFailure,
    getBannersSuccess,
    getHelpfulResourcesPending,
    getHelpfulResourcesFailure,
    getHelpfulResourcesSuccess,
    getActiveServicesCountPending,
    getActiveServicesCountFailure,
    getActiveServicesCountSuccess
} from "./allservices.actions";

export function* getAllServices() {
    try {
        const { X_API_KEY, API_URL: { GET_ALL_SERVICES_API } } = CONFIG;
        yield put(getAllServicesPending());
        const endpoint = GET_ALL_SERVICES_API();
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        res[2] = {
            services: [res[2], res[3]],
            multipleServices: true
        };
        res.splice(3, 1);
        yield put(getAllServicesSuccess(res));
    } catch (error) {
        console.error("error: ", error);
        window.location.href = window.location.origin + '/404';
        yield put(getAllServicesFailure(error?.message));
    }
}

export async function saveMFU(serviceId) {
    try {
        const { X_API_KEY, API_URL: { SAVE_MFU_API } } = CONFIG;
        const accessToken = authService.getAccessToken();
        const decodedToken = jwt_decode(accessToken);
        let hrId;
        if (decodedToken?.hrid) {
            hrId = decodedToken.hrid
        } else {
            hrId = '0';
        }
        const endpoint = SAVE_MFU_API();
        await doAxiosRequest({
            method: 'post',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            },
            params: {
                "ServiceId": serviceId,
                "HrId": hrId,
                "buttonClicked": "r"
            }
        });
    } catch (error) {
        console.error("error: ", error)
    }
}

export function* getMFU() {
    try {
        const { X_API_KEY, API_URL: { GET_MFU_API } } = CONFIG;
        yield put(getMFUPending());
        const endpoint = GET_MFU_API();
        const accessToken = authService.getAccessToken();
        const decodedToken = jwt_decode(accessToken);
        let hrId;
        if (decodedToken?.hrid) {
            hrId = decodedToken.hrid
        } else {
            hrId = '0';
        }
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint + '/' + hrId,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        yield put(getMFUSuccess(res.topServices));
    } catch (error) {
        console.error("error: ", error)
        //window.location.href = window.location.origin + '/404';
        yield put(getMFUFailure(error?.message));
    }
}

export function* getActiveServicesCount() {
    try {
        const { X_API_KEY, API_URL: { GET_ASC_API } } = CONFIG;
        yield put(getActiveServicesCountPending());
        const endpoint = GET_ASC_API();
        const accessToken = authService.getAccessToken();
        const decodedToken = jwt_decode(accessToken);
        let hrId;
        if (decodedToken?.hrid) {
            hrId = decodedToken.hrid
        } else {
            hrId = '0';
        }
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint + '/' + hrId,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        yield put(getActiveServicesCountSuccess(res.count));
    } catch (error) {
        console.error("error: ", error);
        //window.location.href = window.location.origin + '/404';
        yield put(getActiveServicesCountFailure(error?.message));
    }
}

export function* getBanners() {
    try {
        const { X_API_KEY, API_URL: { GET_BANNERS_API } } = CONFIG;
        yield put(getBannersPending());
        const endpoint = GET_BANNERS_API();
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        yield put(getBannersSuccess(res));
    } catch (error) {
        console.error("error: ", error);
        //window.location.href = window.location.origin + '/404';
        yield put(getBannersFailure(error?.message));
    }
}

export function* getHelpfulResources() {
    try {
        const { X_API_KEY, API_URL: { GET_HELPFUL_RESOURCES_API } } = CONFIG;
        yield put(getHelpfulResourcesPending());
        const endpoint = GET_HELPFUL_RESOURCES_API();
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        yield put(getHelpfulResourcesSuccess(res));
    } catch (error) {
        console.error("error: ", error);
        window.location.href = window.location.origin + '/404';
        yield put(getHelpfulResourcesFailure(error?.message));
    }
}

export default function* allservicesSaga() {
    try {
        yield takeEvery(ALL_SERVICES.GET_ALL_SERVICES, getAllServices);
        yield takeEvery(ALL_SERVICES.GET_MFU, getMFU);
        yield takeEvery(ALL_SERVICES.GET_BANNERS, getBanners);
        yield takeEvery(ALL_SERVICES.GET_HELPFUL_RESOURCES, getHelpfulResources);
        yield takeEvery(ALL_SERVICES.GET_ACTIVE_SERVICES_COUNT, getActiveServicesCount);
    } catch (error) {
        yield put(moduleFailure(error, 'User Session Saga'));
    }
}