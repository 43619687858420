const PRIMARY_CATEGORY = {
  CTS: "Case Team Services",
};

const DTMRULE = {
  OP_SUB_CATEGORY_EVENT: "OP_Resources_Support_Events",// event 26
  OP_CATEGORY_BUTTON_EVENTS: "OP_In_Around_Events",// event 70
  SORT_FILTER_CLICKS: "Sort_Filter_Clicks",
  INTERACTIONS_TABS: "Interactions_Tab"
}

const PAGES = {
  CTS_HOME: "CTS_HOME",
  CTS_MY_REQUESTS: "CTS_MY_REQUESTS",
  CTS_HELPFUL_RESOURCES: "CTS_HELPFUL_RESOURCES",
};
const TOOLNAME = {
  CASE_TEAM_SERVICES: "Minerva"
};

export { PRIMARY_CATEGORY, PAGES, DTMRULE, TOOLNAME };
