import Hashes from "jshashes";
import CONFIG from "config";
import * as utils from '@kdshared/okta-utils';
import { removeFromLocalStorage } from 'utils/localStorage/localStorage';
import Cookies from 'js-cookie';
import authService from 'redux/auth';
import jwt_decode from "jwt-decode";

const {
  OKTA: {
    AUTH_ATTEMPTED,
    SMARTLOGIC_TOKEN_KEY
  }
} = CONFIG;

export async function getUserEmail() {
  try {
    const accessToken = await authService.getAccessToken();
    const decodedToken = jwt_decode(accessToken);
    if (decodedToken?.sub) {
      return decodedToken.sub;
    } else {
      return 'unknown'
    }
  } catch (err) {
    return "unknown";
  }
};
export const getStaffEmailID = async () => {
  const userEmail = await getUserEmail();
  return new Hashes.SHA256().hex(userEmail.toLowerCase());
};
// retrieves claims of logged-in user to fetch HR ID
export async function getUserId() {
  const accessToken = await authService.getAccessToken();
  // const get me toke from local storgae
  const userId = !!accessToken ? JSON.parse(atob(accessToken.split('.')[1])).hrid : "0";
  return userId;
};

/**
 * ===============================================================================
 * OPERATIONS RELATED TO OKTA TOKENS
 * ===============================================================================
 */

export function clearAuthCache() {
  console.error('OKTA auth 001 - clearing cache');
  utils.clearOktaCookies(CONFIG.ENVIRONMENT_MAIN);
  removeFromLocalStorage(SMARTLOGIC_TOKEN_KEY);
};
export function setAuthAttemptTime() {
  Cookies.set(AUTH_ATTEMPTED, new Date());
};
export function isAuthAttemptSet() {
  return Cookies.get(AUTH_ATTEMPTED) !== undefined;
};
export function getSecondsSinceLastAttempt() {
  const lastRetryTime = new Date(Cookies.get(AUTH_ATTEMPTED));
  const now = new Date();
  const durationInMs = now - lastRetryTime;

  return durationInMs / 1000;
};
export function checkDurationAndReload(durationInSeconds) {
  if (getSecondsSinceLastAttempt() > durationInSeconds) {
    clearAuthCache();
    setAuthAttemptTime();
    // eslint-disable-next-line no-console
    console.error('OKTA auth 002 - reloading page');
    if (window.location.href.indexOf('/error') > -1) {
      window.location.href = '/';
    } else {
      window.location.reload();
    }
  }
};
export function getOktaExpirations() {
  try {
    const accessToken = Cookies.get(CONFIG.OKTA.ACCESS_TOKEN_STORAGE);
    const idToken = Cookies.get(CONFIG.OKTA.ID_TOKEN_STORAGE);
    const oktaAccessExpiration = new Date(JSON.parse(accessToken).expiresAt * 1000).toString();
    const oktaIdExpiration = new Date(JSON.parse(idToken).expiresAt * 1000).toString();
    return [oktaAccessExpiration, oktaIdExpiration];
  } catch {
    return [null, null];
  }
};
export async function isUserAuthenticated() {
  try {
    const accessToken = await authService.getAccessToken();
    console.log('OKTA is user authenticated', !!accessToken);
    return !!accessToken;
  } catch {
    return false;
  }
}











