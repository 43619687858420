import PAGE_ANALYTICS from "./page_analytics";
import { DTMRULE, TOOLNAME, PAGES, PRIMARY_CATEGORY } from "./analytics_constants";
import { getStaffEmailID } from "utils/auth/auth";
export default {
  /**
   *
   * @param {*} Object - having adobeAnalyticsData
   * @param {*} string - having dtmRule
   */

  async sendEventData(
    adobeAnalyticsData, dtmRuleConst,
    pageName,
    toolName = TOOLNAME?.CASE_TEAM_SERVICES,
    primaryCategory = PRIMARY_CATEGORY?.CTS
  ) {
    let dtmRule = DTMRULE[dtmRuleConst];
    const staffEmailID = await getStaffEmailID();
    const pageInfoObj = {
      headerInfo: {
        staffEmailID: staffEmailID,
        toolName: toolName
      },
      pageInfo: {
        pageName: pageName ? PAGES[pageName] : PAGES.CTS_HOME,
      },
      category: {
        primaryCategory: primaryCategory,
      },
    };
    adobeAnalyticsData = {
      ...adobeAnalyticsData,
      page: {
        ...adobeAnalyticsData['page'],
        ...pageInfoObj
      }
    }
    window.digitalData = adobeAnalyticsData;
    PAGE_ANALYTICS.track(dtmRule);
  },
};
