import React, { PureComponent } from "react";
import history from "redux/history";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { store, runSaga } from "redux/store";
import CONFIG from "config";
import { Route, Switch } from "react-router-dom";
import { Security, SecureRoute } from "@okta/okta-react";
import ImplicitCallbackWrapper from "components/ImplicitCallback/ImplicitCallback";
import authService from "redux/auth";
import { toRelativeUrl } from '@okta/okta-auth-js';

import ErrorPage from "containers/ErrorPage/ErrorPage";

import { Home } from "containers/Home";
import { MyRequests } from "containers/MyRequests";
import { HelpfulResources } from "containers/HelpfulResources";
import rootSaga from "redux/sagas";

import { isIE } from 'utils/helpers/helpers';
import CONSTANTS from 'globalConstants';
import { getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { deleteCookie, getCookie } from "utils/cookies/cookies";

const {
  UI_URL: {
    MY_REQUESTS,
    HOME,
    ERROR,
    HELPFUL_RESOURCES
  },
  OKTA: {
    REDIRECT_URL
  },
} = CONFIG;

const { ERROR_IE } = CONSTANTS;

export class App extends PureComponent {
  componentDidMount() {
    runSaga(rootSaga);
    const { location: { pathname } } = window;
    if (isIE() && pathname !== ERROR(ERROR_IE)) {
      window.location.replace(ERROR(ERROR_IE));
    }
    if (getFromLocalStorageAsJSON(CONFIG.OKTA.SMARTLOGIC_TOKEN_KEY)) {
      removeFromLocalStorage(CONFIG.OKTA.SMARTLOGIC_TOKEN_KEY);
    }
    if (getCookie(CONFIG.OKTA.SMARTLOGIC_TOKEN_KEY)) {
      deleteCookie(CONFIG.OKTA.SMARTLOGIC_TOKEN_KEY);
    }
  }
  restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Security oktaAuth={authService} restoreOriginalUri={this.restoreOriginalUri}>
            <Switch>
              <SecureRoute
                exact
                path={HOME}
                component={Home}
              />
              <SecureRoute
                exact
                path={MY_REQUESTS}
                component={MyRequests}
              />
              <SecureRoute
                exact
                path={HELPFUL_RESOURCES}
                component={HelpfulResources}
              />
              <SecureRoute
                path={ERROR}
                component={ErrorPage}
              />
              <Route
                path={REDIRECT_URL}
                component={ImplicitCallbackWrapper}
              />
            </Switch>
          </Security>
        </ConnectedRouter>
      </Provider>
    );
  }
}