import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getActiveUserSession } from "redux/actions/usersession/usersession.actions";
import { selectors as userSessionSelector } from "redux/reducers/usersession/usersession_reducer";
import { selectors as allServicesSelector } from "redux/reducers/allservices/allservices_reducer";
import Image from "components/shared/Image";
import Spinner from "assets/images/spinner.gif";
import { getHelpfulResources } from "redux/actions/allservices/allservices.actions";
import { HelpfulResource } from "../../components/HelpfulResource";
import analytics from "../../utils/analytics/analytics";
import "./HelpfulResources.scss";
import { PAGES } from "../../utils/analytics/analytics_constants";

import { Layout } from "containers/Layout";
const HelpfulResourcesContainer = ({ getHelpfulResources, isHelpfulResourcesLoading, helpfulResources }) => {
    useEffect(async () => {
        getHelpfulResources();

        await analytics.page.initialize({
            page: PAGES.CTS_HELPFUL_RESOURCES,
            trackPageLoad: true
        });
    }, []);
    return (
        <Layout id="chat" layoutClass="layout__chat-section" activeRoute="HelpfulResources">
            <div className="helpful-resources-wrapper">
                {
                    isHelpfulResourcesLoading
                        ? <Image className="spinner" src={Spinner} alt="Spinner" />
                        : <div className="helpful-resources">
                            {
                                helpfulResources.length > 0 && helpfulResources.map((hr) =>
                                    <HelpfulResource
                                        key={hr.id}
                                        data={hr}
                                    />
                                )
                            }
                        </div>
                }
            </div>
        </Layout >
    );
};

const mapStateToProps = (state) => ({
    activeUserSessionId: userSessionSelector.getActiveUserSession(state),
    helpfulResources: allServicesSelector.getHelpfulResources(state),
    isHelpfulResourcesLoading: allServicesSelector.isHelpfulResourcesLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    getCurrentUserSession: () => dispatch(getActiveUserSession()),
    getHelpfulResources: () => dispatch(getHelpfulResources()),
});


export const HelpfulResources = connect(
    mapStateToProps,
    mapDispatchToProps
)(HelpfulResourcesContainer);

export { HelpfulResourcesContainer };
