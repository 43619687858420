import React, { useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { getActiveUserSession } from "redux/actions/usersession/usersession.actions";
import { selectors as userSessionSelector } from "redux/reducers/usersession/usersession_reducer";
import { getAllServices } from "redux/actions/allservices/allservices.actions";
import { selectors as allServicesSelector } from "redux/reducers/allservices/allservices_reducer";
import { Service } from "../../components/Service";
import Image from "components/shared/Image";
import Spinner from "assets/images/spinner.gif";
import { ensureInView, isInViewport, sendAdobeAnaltics } from "utils/helpers/helpers";
import analytics from "../../utils/analytics/analytics";
import { PAGES } from "../../utils/analytics/analytics_constants";
import "./Home.scss";

// Constant
import { ALL_SERVICES } from "redux/constants";


import { Layout } from "containers/Layout";
const HomeContainer = ({
    allServices,
    getAllServices,
    isallServicesLoading
}) => {

    useEffect(() => {
        let filterHandler = (e) => {
            if (
                e.target.className !== 'request-now' &&
                e.target.className !== 'learn-button' &&
                e.target.className !== 'sub-service-content' &&
                e.target.className !== 'sub-service-header' &&
                e.target.className !== 'sub-service-title' &&
                e.target.className !== 'ui image arrow-down' &&
                e.target.className !== 'category-link-url' &&
                e.target.className !== 'sub-service-expanded' &&
                !(e.target.className.includes('ui button') && e.target.textContent === 'Accept') &&
                !(e.target.className.includes('MillerLinksstyles') || e.target.className.includes('MillerColumnsstyles'))
            ) {
                const newAllServices = [...allServices];
                newAllServices.forEach((service) => {
                    service.services.forEach((subService) => {
                        subService.isExpanaded = false;
                    });
                    if (service.multipleServices) {
                        service.services.forEach((service1) => {
                            service1.services.forEach((subService) => {
                                subService.isExpanaded = false;
                            });
                        });
                    }
                });
                dispatch({ type: ALL_SERVICES.GET_ALL_SERVICES_SUCCESS, payload: { data: newAllServices } });
            }
        }
        document.addEventListener("mousedown", filterHandler);

        return () => {
            document.removeEventListener("mousedown", filterHandler)
        }
    })

    const dispatch = useDispatch();
    const serviceRefs = useRef([]);
    const subServiceRefs = useRef([]);


    // Initialize the nested array of refs for inputs
    if (serviceRefs.current.length !== allServices.length) {
        serviceRefs.current = Array.from({ length: allServices.length }, () => React.createRef());
    }

    // Initialize the nested array of refs for inputs
    if (subServiceRefs.current.length !== allServices.length) {
        subServiceRefs.current = Array.from({ length: allServices.length }, () =>
            Array.from({ length: 20 }, () => React.createRef())
        );
    }

    useEffect(async () => {
        getAllServices();
        await analytics.page.initialize({
            page: PAGES.CTS_HOME,
            trackPageLoad: true
        });
    }, []);

    const serviceClick = async (id, index, subServiceIndex, level, serviceId, subCategory, category) => {
        const newAllServices = [...allServices];
        if (level !== 2) {
            const elem = subServiceRefs.current[index][subServiceIndex].current;
            newAllServices.forEach((service, cindex) => {
                service.services.forEach((subService, csubServiceIndex) => {
                    if (subService.id === id) {
                        subService.isExpanaded = !subService.isExpanaded;
                        elem.style.display = elem.style.display === "none" ? "flex" : "none";
                    } else {
                        subService.isExpanaded = false;
                        if (subServiceRefs.current[cindex][csubServiceIndex].current)
                            subServiceRefs.current[cindex][csubServiceIndex].current.style.display = "none";
                    }
                });
                if (service.multipleServices) {
                    service.services.forEach((service1) => {
                        service1.services.forEach((subService) => {
                            subService.isExpanaded = false;
                        });

                    });
                }
            });

            subServiceRefs.current[index][subServiceIndex].current.style.display = "flex";
            ensureInView(serviceRefs.current[index].current, subServiceRefs.current[index][subServiceIndex].current);

            let windowScroll = isInViewport(subServiceRefs.current[index][subServiceIndex].current);
            let existingScroll = window.scrollY;
            window.scrollTo(0, existingScroll + windowScroll);
        } else {
            newAllServices.forEach(service1 => {
                if (service1.multipleServices) {
                    service1.services.forEach((service) => {
                        if (service.id === serviceId) {
                            service.services.forEach((subService) => {
                                if (subService.id === id) {
                                    subService.isExpanaded = !subService.isExpanaded;
                                } else {
                                    subService.isExpanaded = false;
                                }
                            });
                        } else {
                            service.services.forEach((subService) => {
                                subService.isExpanaded = false;
                            });
                        }
                    });
                } else {
                    service1.services.forEach((subService, csubServiceIndex) => {
                        subService.isExpanaded = false;
                    });
                }
            })
        }

        dispatch({ type: ALL_SERVICES.GET_ALL_SERVICES_SUCCESS, payload: { data: newAllServices } });

        if (subCategory)
            await sendAdobeAnaltics("NA", subCategory, category, "OP_SUB_CATEGORY_EVENT", "All Services");
    }

    return (
        <Layout id="chat" activeRoute="Home">
            <div className="all-services-wrapper">
                {
                    isallServicesLoading
                        ? <Image className="spinner" src={Spinner} alt="Spinner" />
                        : <div className="all-services">
                            {
                                allServices.length > 0 && allServices.map((service, index) =>
                                    <Service
                                        key={service.id}
                                        serviceRef={serviceRefs.current[index]}
                                        subServiceRefs={subServiceRefs.current[index]}
                                        data={service}
                                        serviceClick={serviceClick}
                                        index={index} />
                                )
                            }
                        </div>
                }
            </div>
        </Layout >
    );
};

const mapStateToProps = (state) => ({
    activeUserSessionId: userSessionSelector.getActiveUserSession(state),
    allServices: allServicesSelector.getAllServices(state),
    isallServicesLoading: allServicesSelector.isallServicesLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    getCurrentUserSession: () => dispatch(getActiveUserSession()),
    getAllServices: () => dispatch(getAllServices()),
});


export const Home = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeContainer);

export { HomeContainer };
