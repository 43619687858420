import LABELS from 'labels';
import analytics from "../../utils/analytics/analytics";
/**
 * @description this function creates copy of an object
 * @param obj as an object 
 * @returns object copy
 */
export const copyObject = (obj) => {
  if (!!obj && typeof obj === 'object') {
    return JSON.parse(JSON.stringify(obj));
  }
  return obj;
};

/**
 * @desc this method will scroll page to the top
 */
export const scrollToTop = (location = 0) => {
  window.scrollTo({ top: location, behavior: 'smooth' });
}

// Check if the browser is IE
export const isIE = () => {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  // IE 10 or older
  const msie = ua.indexOf('MSIE ');
  // IE 11
  const trident = ua.indexOf('Trident/');
  return msie > -1 || trident > -1;
}


/**
 * Opens the url in new tab or same tab
 * @param {url} String
 * @param {newTab} boolean
 */
export const openURL = (url, newTab) => {
  if (newTab) {
    window.open(url, '_blank');
  }
  else {
    window.open(url);
  }
}


export const removeCurlyBracketFromId = (id) => {
  return id?.indexOf('{') > -1 ? id.substring(1, id.length - 1) : id
}

export const removeCurlyBrackets = (ids = []) => {
  if (Array.isArray(ids))
    return ids.map(id => removeCurlyBracketFromId(id));
  else
    return removeCurlyBracketFromId(ids)
}

export const isValidData = (content) => {
  const { GLOBAL: { RESTRICTED } } = LABELS;
  const isNotValid = typeof content === "undefined" || !content.length || content.trim === "" || content.toLowerCase() === 'null' || content.trim().toLowerCase() === RESTRICTED;
  return !isNotValid
}

export const addColonToValidData = (content) => {
  return isValidData(content) ? content + " : " : ""
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getTimeStampDate = (date) => {
  if (date && date.length > 0) {
    const dateArr = date.split("-")
    return new Date(`${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`).getTime()
  }
}

export const sortArrayObjectByDate = (data, key) => {
  if (data || data.length > 0) {
    return data.sort((a, b) => getTimeStampDate(b.data[key]) - getTimeStampDate(a.data[key]))
  }
}

export const replaceInLabel = (originalString, searchValue, replaceWith) => {
  if (Array.isArray(replaceWith)) {
    let replacestr = originalString;
    replaceWith.forEach(current => {
      replacestr = replacestr.replace(searchValue, current)
    })
    return replacestr;
  } else {
    return originalString && originalString.replace(searchValue, replaceWith)
  }
}

export const getStartEndPagination = (size, number, total) => {
  return {
    start: size * (number - 1) + 1,
    end: size * number > total ? total : size * number,
  }
}

export const UpdatePageUrlWithoutRefresh = (url, history) => {
  history.replace({
    pathname: url,
    state: { mode: history.location?.state?.mode },
  });
}

export const UpdatePageQueryParamsWithoutRefresh = (url, history, search) => {
  history.replace({
    pathname: url,
    search
  });
}

/**
 * @description - Check both arrays contains same element
 * @param - array1, array 2
 * @returns - boolean
*/
export const findCommonElements = (arr1, arr2) => {
  return arr1.some(item => arr2.includes(item))
}

export const checkIsGivenValueIsAArray = (value) => {
  return value && Array.isArray(value);
}

export const checkIsGivenValueIsAObj = (value) => {
  return typeof value === 'object';
}

/**
 * @desc check whether an object is empty or not
 * @param {Object} obj ==> object to be checked for emptiness
 * @returns {Boolean} true if isEmpty else false
 */
export const isObjEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }
  return true;
}


export const ensureInView = (container, element) => {
  //Determine container top and bottom
  let cTop = container.scrollTop;
  let cBottom = cTop + container.clientHeight;

  //Determine element top and bottom
  let eTop = element.offsetTop;
  let eBottom = eTop + element.clientHeight;
  //Check if out of view
  if (eTop < cTop) {
    container.scrollTop -= (cTop - eTop);
  }
  else if (eBottom > cBottom) {
    container.scrollTop += (eBottom - cBottom + 5);
  }
}

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  const scrollTop = rect.top - 60;
  const scrollBottom = rect.bottom - (window.innerHeight || document.documentElement.clientHeight)
  return (
    (scrollTop > 0 ? false : scrollTop) ||
    (scrollBottom < 0 ? 0 : (scrollBottom + 5))
  );
}


export const sendAdobeAnaltics = async (link, subCategory, mainCategory, dtmRule, tabName, page, search) => {
  // Click base analytics events:
  const data = {
    officePage: {
      link,
      subCategory,
      mainCategory
    },
    rc: {
      tabName
    },
    search
  };
  await analytics.cts.sendEventData(data, dtmRule, page);
}
