import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Image from "components/shared/Image";
import CaseTeamServicesLogo from "assets/images/Case-Team-Services-Logo.svg";
import Announcement from "assets/images/announcement.svg";
import Outage from "assets/images/outage.svg";
import GreyClose from "assets/images/grey-close.svg";
import { withRouter } from "react-router";
import CONFIG from "config";
import { BCGHeader } from '@kdshared/global-nav';
import { ErrorBoundary } from "containers/ErrorBoundary/ErrorBoundary";
import { Tab, MenuItem } from 'semantic-ui-react'
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import "./Layout.scss";
import { usabillaSetupFn } from "../../utils/usabilla/usabilla.min.js";
import { setLoggedInUserData } from "redux/global.action";
import { getMFU, getBanners, getBannersSuccess, getActiveServicesCount } from "redux/actions/allservices/allservices.actions";
import { saveMFU } from "redux/actions/allservices/allservices.saga";
import { selectors as allServicesSelector } from "redux/reducers/allservices/allservices_reducer";
import ReactHtmlParser from 'html-react-parser';
import { sendAdobeAnaltics } from "utils/helpers/helpers";
import { PAGES } from "../../utils/analytics/analytics_constants";
import authService from 'redux/auth';

export class LayoutContainer extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      accessToken: ""
    }
  }



  componentDidMount = async () => {
    Promise.resolve(authService.getAccessToken()).then((token) => {
      this.setState({ accessToken: token });
    });
    usabillaSetupFn();
    if (!this.props.isErrorPage) {
      this.props.getMFU();
      this.props.getBanners();
      this.props.getActiveServicesCount();
    }
  }

  changeRoute = (url = "/") => {
    const { history } = this.props;
    history.push(url);
  };

  render = () => {
    const {
      children,
      isBodyScrollable,
      activeRoute
    } = this.props;
    const panes = [
      { menuItem: 'All Services', render: () => <Tab.Pane attached={false}>{children}</Tab.Pane> },
      {
        menuItem: (
          <MenuItem key='My Requests'>
            My Requests
            {!this.props.isASCountLoading &&
              <div class="sr-count-container">
                <span class="sr-count">
                  <span>{this.props.asCount}</span>
                </span>
              </div>
            }
          </MenuItem>
        ), render: () => <Tab.Pane attached={false}>{children}</Tab.Pane>
      },
      { menuItem: 'Helpful Resources', render: () => <Tab.Pane attached={false}>{children}</Tab.Pane> },
    ];
    const activeRouteNum = activeRoute === "MyRequests" ? 1 : 2;
    const activeIndex = activeRoute === "Home" ? 0 : activeRouteNum;
    let activeIndexName = activeIndex === 1 ? "My Requests" : "Helpful Resources";
    const tabName = activeIndex === 0 ? "All Services" : activeIndexName;
    activeIndexName = activeIndex === 1 ? PAGES.CTS_MY_REQUESTS : PAGES.CTS_HELPFUL_RESOURCES
    const pageName = activeIndex === 0 ? PAGES.CTS_HOME : activeIndexName;

    const handleChange = async (e, { activeIndex }) => {
      let activeIndexName = activeIndex === 1 ? "My Requests" : "Helpful Resources";
      const tabName1 = activeIndex === 0 ? "All Services" : activeIndexName;
      activeIndexName = activeIndex === 1 ? PAGES.CTS_MY_REQUESTS : PAGES.CTS_HELPFUL_RESOURCES
      const pageName1 = activeIndex === 0 ? PAGES.CTS_HOME : activeIndexName;

      switch (activeIndex) {
        case 0:
          this.changeRoute("/");
          break;
        case 1:
          this.changeRoute("/my-requests");
          break;
        case 2:
          this.changeRoute("/helpful-resources");
          break;
        default:
          break;
      }
      setTimeout(async () => {
        await sendAdobeAnaltics(null, null, null,
          "INTERACTIONS_TABS",
          tabName1,
          pageName1);
      }, 0);
    };

    const buttonClick = async (serviceId, buttonText, activeIndex) => {
      await saveMFU(serviceId);
      const subCategory = "NA";
      const category = "My most frequently used";
      await sendAdobeAnaltics(buttonText, subCategory, category, "OP_CATEGORY_BUTTON_EVENTS",
        tabName,
        pageName);
    }

    const closeBanner = () => {
      const newBanners = this.props.banners.filter(banner => banner.type !== 'annoucement' && banner.type !== 'outage');
      this.props.getBannersSuccess(newBanners);
    }

    const buttonClickForEmail = async (linkUrl, serviceId, buttonText, activeIndex) => {
      eval(linkUrl);
      buttonClick(serviceId, buttonText, activeIndex);
    }
    return (
      <div className={`layout layout__page ${!isBodyScrollable ? 'layout--unscrollable' : ''}`}>
        {this.state.accessToken !== "" && <BCGHeader environment={CONFIG.ENVIRONMENT_MAIN === 'local' ? 'dev' : CONFIG.ENVIRONMENT_MAIN}
          apiConfig={CONFIG.SHARED_CONFIG}
          isPeopleApiInternal={true}
          token={this.state.accessToken}
        />}
        <div className={`main`}>
          <Fragment>
            <ErrorBoundary>

              <div class="component-content">
                <div class="banner-bcg field-promoicon">
                  <div class="cts-header">
                    <div class="briefcase-icon field-promoicon2">
                      <Image src={CaseTeamServicesLogo} alt="CaseTeamServicesLogo" />
                    </div>
                    <div class="case-desc">
                      <strong class="case-text">Case Team Services Platform</strong>
                      <span class="field-promotext2">Discover all the services on offer to support your case work</span>
                    </div>
                  </div>

                </div>

                <div id="wrapper">
                  {!this.props.isBannersLoading && this.props.banners.filter(banner => banner.type === 'annoucement' || banner.type === 'outage').length > 0 &&
                    <div class="announcements-parent">
                      {this.props.banners.filter(banner => banner.type === 'annoucement' || banner.type === 'outage').map(banner => (
                        banner.type === 'annoucement' ?
                          <div class="announcement">
                            <div class="announcement-icon">
                              <Image src={Announcement} alt="Announcement" />
                            </div>
                            <div class="announcement-heading">
                              <strong>WW Announcement: </strong>
                            </div>
                            <div class="announcement-text">
                              <div class="field-promotext">
                                {ReactHtmlParser(banner.bannerText)}
                              </div>
                            </div>
                          </div> :
                          <div class="outage">
                            <div class="outage-icon">
                              <Image src={Outage} alt="Outage" />
                            </div>
                            <div class="outage-heading">
                              <strong>Unexpected Outage: </strong>
                            </div>
                            <div class="outage-text">
                              <div class="field-promotext2">
                                {ReactHtmlParser(banner.bannerText)}
                              </div>
                            </div>
                          </div>
                      ))}
                      <div class="close-icon">
                        <Image src={GreyClose} alt="GreyClose" onClick={closeBanner} />
                      </div>
                    </div>
                  }
                  {!this.props.isMFULoading && this.props.mfuServices.length > 0 &&
                    <div class="mfu-container">
                      <div className="mfu-heading">My Most Frequently Used</div>
                      <div className="mfu-row">
                        {
                          this.props.mfuServices.map((service, index) => (
                            <>
                              {
                                (service.serviceUrl && service.serviceUrl.indexOf("javascript:window.open") === -1) ?
                                  <a class="mfu-button" rel="noreferrer" id={service.serviceId} href={service.serviceUrl} target="_blank" onClick={() => buttonClick(service.serviceId, service.serviceName, activeIndex)}>
                                    {service.serviceName}
                                  </a> :
                                  <a class="mfu-button" rel="noreferrer" id={service.serviceId} onClick={() => buttonClickForEmail(service.serviceUrl, service.serviceId, service.serviceName, activeIndex)}>
                                    {service.serviceName}</a>
                              }
                            </>

                          ))
                        }
                      </div>
                    </div>
                  }
                  <div>
                    {!this.props.isErrorPage ?
                      <Tab className={"menu-tab"}
                        activeIndex={activeIndex}
                        menu={{ secondary: true, pointing: true }}
                        panes={panes}
                        onTabChange={handleChange} /> :
                      children
                    }
                  </div>
                </div >
              </div>
            </ErrorBoundary>
          </Fragment>
        </div>
      </div >
    );
  };
}

LayoutContainer.propTypes = {
  children: PropTypes.node,
  layoutClass: PropTypes.string
};

LayoutContainer.defaultProps = {
  layoutClass: "",
};

const mapStateToProps = (state) => ({
  mfuServices: allServicesSelector.getMFU(state),
  isMFULoading: allServicesSelector.isMFULoading(state),
  banners: allServicesSelector.getBanners(state),
  isBannersLoading: allServicesSelector.isBannersLoading(state),
  asCount: allServicesSelector.getASCount(state),
  isASCountLoading: allServicesSelector.isASCountLoading(state)
});

const mapDispatchToProps = (dispatch) => ({
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
  setLoggedInUserData: (data) => dispatch(setLoggedInUserData(data)),
  getMFU: () => dispatch(getMFU()),
  getBanners: () => dispatch(getBanners()),
  getBannersSuccess: (data) => dispatch(getBannersSuccess(data)),
  getActiveServicesCount: () => dispatch(getActiveServicesCount()),
});

export const Layout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)
);