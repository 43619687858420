// developer utils we can run in dev console
import { getOktaExpirations } from 'utils/auth/auth';

export default () => {
    /**
     * logs out the expiration times for the okta access and id tokens
     */
    window.bcgTokenExpiration = () => {
        const [oktaAccessExpiration, oktaIdExpiration] = getOktaExpirations();
        console.log('access token expiration:', oktaAccessExpiration);
        console.log('id token expiration    :', oktaIdExpiration);
    };
};
