// React
import React from 'react';
import Image from "components/shared/Image";
import ErrorImage from "assets/images/error_image.png";
// scss
import './Error.scss';

export const Error = ({ code, btnClick, btn2Click }) => {

  return (
    <div className="errorWrapper cts-error-page" >
      <h1 className="errorWrapper__heading">
        <Image src={ErrorImage} alt="ErrorImage" className="error-image" />
      </h1>
      <div className="errorWrapper__message">LOOKS LIKE WE ARE EXPERIENCING AN ISSUE!</div>
      <div className="errorWrapper__helpText">Please click REFRESH or RAISE an ISSUE</div>
      <br />

      <div className="margin-auto">
        <a href="/" className="btn primary button">Refresh</a>
        <a href="mailto:ITServiceCenter@bcg.com?subject=Issue accessing Case Team Services Platform"
          className="btn primary secondary button">Raise
          an
          issue</a>
      </div>
      <br></br><br></br>

      <div class="errorWrapper__helpText links-text">In the meanwhile, till the page comes up please find links to
        the services
        for quick access</div>

      <br></br>
      <div class="service-links-wrapper">
        | <a target="_blank" rel="noreferrer" href="https://bcg.service-now.com/assistmesp?id=sc_cat_item_1&amp;sys_id=3283db0191bf0200610dd2fa4b06b664">Research,
          Knowledge &amp; Expertise
          (DRS, PA KT)</a> | <a target="_blank" rel="noreferrer" href="https://research.bcg.com/">Research Center
        </a> | <a target="_blank" rel="noreferrer" href="https://bostonconsultinggroup.service-now.com/itconnect?id=sc_cat_item&amp;sys_id=a4b1fc76dbe1dc10b7529ec2ca9619fc">External
          Expert Networks</a> | <a target="_blank" rel="noreferrer" href="https://bcg.service-now.com/OutsourcedResearch/">Outsourced Research</a> |
      </div>
      <div class="service-links-wrapper">
        | <a target="_blank" rel="noreferrer" href="mailto:GAMMA@bcg.com?subject=">Analytics
        </a> | <a target="_blank" rel="noreferrer" href="https://bostonconsultinggroup.service-now.com/vs?id=vs_gp&amp;tab=request">Global
          Production
          (External Partners)
        </a> | <a target="_blank" rel="noreferrer" href="https://bostonconsultinggroup.service-now.com/vs">BCG Design studios</a> |
      </div>
      <div class="service-links-wrapper">
        | <a target="_blank" rel="noreferrer" href="https://bcg.service-now.com/assistmesp?id=sc_cat_item_1&amp;sys_id=1aa1255bdb6000503018e5b74b961915">
          Proposal
          Support (PEX)</a> | <a target="_blank" rel="noreferrer" href="https://mycases.bcg.com/create-case">
          Share Point Online</a> |
      </div>
    </div>
  )
}

export default Error;